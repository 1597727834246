@import '../scss/zest-admin/variables';
.hand-cursor {
  cursor: pointer;
}

.custom-title {
  font-size: 18px;
  margin-left: 10px;
  padding: 10px 5px 10px 0px;
  font-weight: 600;
}

.mandatory-field {
  color: red;
}

.select-image-div {
  width: 50%;
  height: 30px;
  background-color: gray;
  border-radius: 50%;
}

.rounded-circle {
  // width: 25% !important;
  width: 140px;
  height: 140px !important;
  cursor: pointer !important;
}

.pagination-position {
  margin-top: 10%;
}

.custom-login-page {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.sidebar-color {
  background-color: #2b3f7a !important;
}

.wone-loader {
  // position: fixed !important;
  // top: 50% !important;
  // right: 0 !important;
  // bottom: 0 !important;
  // left: 50% !important;
  // // background: white;
  // width: 10%;
  // z-index: 9999 !important;
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.login-page-msg {
  color: white;
  margin-top: 20px;
  font-weight: bold;
}

.eye-block {
  background-color: white;
  padding: 0px;
  margin: 0px;
}

.rm-p-m {
  margin: 0px !important;
  padding: 0px !important;
  background-color: white;
}

.login-page-custom {
  .btn-login {
    width: 100%;
    background-color: #ed6b64 !important;
    cursor: pointer;
  }
  .label {
    color: white;
  }
  Input {
    color: #ed6b64 !important;
  }
  .remember-me {
    margin-left: 20px;
  }
  .eye-icon {
    font-size: 20px;
    cursor: pointer !important;
  }
  Input {
    border: none !important;
    outline: none !important;
    &:focus {
      background-color: white !important;
    }
    &:hover {
      color: red;
      background-color: none !important;
    }
  }
}

// input{
//     &:focus {
//         border: white !important;
//     }
// }
.not-found {
  margin-top: 10%;
  margin-left: 25%;
}

[data-menu='image3'] .chatbar .sidebar-wrapper {
  background: none !important;
}

[data-menu='image3'] .sidebar:after,
[data-menu='image3'] .off-canvas-sidebar:after {
  background: none !important;
}

// .stylebar .style-settings .menu [data-id="image3"]:before {
//     background: none !important,
//     background-size: none !important,
//     background-repeat: none !important;
//  }
.select-box {
  width: 100%;
}

.custom-pagination {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // background-color: red;
  // color: white;
  text-align: center;
  padding: 20px;
  a {
    background-color: white;
  }
  li {
    font-weight: 500;
    font-size: 16px;
  }
}

.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: $primary-color !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary.active,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: $primary-color !important;
}

.field-width {
  width: 100%;
}

::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ed6b64;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid white;
  /* creates padding around scroll thumb */
}

// img {
//     image-rendering: auto;
//     image-rendering: crisp-edges;
//     image-rendering: pixelated;
//   }
.reactstrap-confirm {
  .modal-title {
    font-size: 24px !important;
  }
  .modal-body {
    font-size: 18px;
  }
}

.delete-icon {
  color: #e57373;
  cursor: pointer;
}

.validation-message {
  color: red;
  margin-top: 1%;
  font-family: 'poppins';
}

.wd-100 {
  width: 100% !important;
}

button.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.new-category {
  color: blue;
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
  i {
    font-size: 12px;
  }
}

.rich-text-editor {
  // p {
  //     margin: 0px !important;
  //     padding: 0PX !important;
  // }
  // select {
  //     height: 10px !important;
  // }
  // .RichTextEditor__block___2Vs_D .RichTextEditor__paragraph___3NTf9 {
  //     padding: 0px !important;
  // }
  // .Dropdown__root___3ALmx select {
  //     height: 10px !important;
  // }
  // .public-DraftStyleDefault-block {
  //     margin: 0px !important;
  //     padding: 0px !important;
  //     height: 100px;
  // }
}

.font-w-600 {
  font-weight: 600;
  font-size: 18px;
}

.custom-editor {
  p {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.rm-p-m-all {
  padding: 0px !important;
  margin: 0px !important;
}

.add-three-dots {
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.add-three-dots-appoint {
  text-decoration: none;
  text-overflow: ellipsis;
  // white-space: nowrap;
}
